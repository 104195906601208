<template>
    <div class="">
      <div class="q-mb-sm q-mt-md">
        <h6 class="title-h6 process-color">{{$t('processes.add_objectif')}}</h6>
        <q-card flat bordered class="q-pa-sm q-mb-md collapsible-div">
          <q-card-section class="justify-center">
            <div class="row q-gutter-sm">
              <base-input-text class="col q-pb-none" :label="$t('processes.fields.type_objectif.label')" placeholder="Description" :color="color"
                v-model="typeObjectif.label" />
              <base-input-text type="number" class="col q-pb-none" :label="$t('processes.fields.millesime.label')" placeholder="Description" :color="color"
                v-model="typeObjectif.millesime" />
            </div>
            <div class="row q-mt-md justify-end">
              <div class="col col-3 q-py-sm q-px-md">
                <q-btn class="full-width" color="process" size="sm" @click="addTypeObjectif()">{{$t('processes.add')}}</q-btn>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div v-if="typeObjectifs.length">
        <div class="q-gutter-sm row q-px-lg q-my-md">
          <div class="col" v-html="$t('processes.fields.millesime.label')" />
          <div class="col-7" v-html="$t('processes.fields.type_objectif.label')" />
          <div class="col-1"></div>
        </div>
        <div v-for="typeObjectif in typeObjectifs" :key="typeObjectif.id" >
          <q-card flat bordered class="q-pa-sm q-mb-none">
            <q-card-section class="q-py-sm">
              <div class="q-gutter-sm row">
                <span class="col" v-html="`${typeObjectif.millesime}`" />
                <span class="col-7" v-html="`${typeObjectif.label}`" />
                <q-icon class="col-1 q-pb-sm cursor-pointer" name="delete_forever" @click="removeTypeObjectif(typeObjectif.id)" size="1.5rem" />
              </div>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: { },

  data () {
    return {
      filter: {},
      loading: false,
      isFormOpen: false,
      isUpdate: this.$route.params.id,
      typeObjectif: {}
    }
  },

  computed: {
    ...mapGetters({
      color: 'pages/getColor',
      isAdmin: 'auth/isAdmin',
      typeObjectifs: 'processes/getProcesses'
    })
  },

  beforeDestroy () {
    this.$store.commit('processes/setProcesses', [])
  },

  mounted () {
    this.$store.commit('pages/setBreadcrumbs', [
      { label: this.$t('global.home'), to: { name: 'home' }, icon: 'home' },
      { label: this.$t('processes.processAgence'), to: { name: '' } }
    ])

    this.$store.commit('pages/setPageTitle', this.$t('processes.title.administration'))

    this.$store.commit('pages/setTabs', [
      { name: 'stage-fiche', label: this.$t('processes.stage_process_suivi_de_recrutement'), to: { name: 'stage-fiche' }, params: { } },
      { name: 'objectif-fiche', label: this.$t('processes.objectif_process_suivi_de_nomination'), to: { name: 'objectif-fiche' }, params: { } }
    ])
  },

  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    store.dispatch('processes/fetchProcesses', { typeProcess: 'consoleAdmin/typeObjectif' })
  },
  methods: {
    addTypeObjectif () {
      let params = {
        ...this.typeObjectif,
        model_type: 'consoleAdmin/typeObjectif'
      }
      this.$store.dispatch('processes/saveProcesses', params).then(data => {
        this.$store.dispatch('processes/fetchProcesses', { typeProcess: 'consoleAdmin/typeObjectif' })
      })
    },
    removeTypeObjectif (idTypeObjectif) {
      let params = {
        id: idTypeObjectif,
        type: 'consoleAdmin/typeObjectif'
      }
      this.$store.dispatch('processes/deleteProcesses', params).then((response) => {
        this.$q.notify({
          message: 'Objectif supprimé',
          color: 'positive'
        })
        this.$store.dispatch('processes/fetchProcesses', { typeProcess: 'consoleAdmin/typeObjectif' })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
